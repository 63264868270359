const linkResolverSitemap = require('./linkResolverSitemap.json');

const linkResolver = ({ node, key, value } = {}) => doc => {
    function returnFullPageSlug(uid) {
        const object = linkResolverSitemap.pages.find(obj => obj.uid === uid);
        return (object) ? object.slug : `/${doc.uid}`;
    }

    return returnFullPageSlug(doc.uid)
 }

module.exports = linkResolver;